<template>
  <textarea
    v-bind="$attrs"
    v-on="listeners"
    :value="value"
  ></textarea>
</template>

<script>
export default {
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        change: (event) => {
          this.$emit('change', event.target.value);
        },
        input: (event) => {
          this.$emit('change', event.target.value);
        },
      };
    },
  },
};
</script>
